import EditPrompt from "./EditPrompt";
import DeletePrompt from "./DeletePrompt";
import TestPrompt from "./TestPrompt";
import usePlaceHolders from "../../hooks/usePlaceHolders";

export default function Prompt({prompt, editPrompt, deletePrompt}) {
    const {error: errorPh, loading: loadingPh, placeHolders, setPlaceHolders, deletePlaceHolder} = usePlaceHolders(prompt.id);

    return (
        <tr>
            <td className={"w-100"}>{prompt.name}</td>
            <td className={"text-nowrap d-flex gap-1"}>
                <EditPrompt prompt={prompt} editPrompt={editPrompt} deletePrompt={deletePrompt}
                            placeHolders={placeHolders} deletePlaceHolder={deletePlaceHolder} loading={loadingPh}
                            setPlaceHolders={setPlaceHolders} error={errorPh}/>
                <DeletePrompt deletePrompt={deletePrompt} prompt={prompt}/>
                <TestPrompt prompt={prompt} placeHolders={placeHolders}/>
            </td>
        </tr>
    );
}